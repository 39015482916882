<template>
  <div class="support-page">
    <div class="readable_content form_content">
      <h5 class="title">{{ title }}</h5>
      <stand-out-block class="light" v-if="helpType">
        <support-contact-form ref="form" :init-help-type="helpType" @submitted="onSubmitted"></support-contact-form>

        <div class="alternative-contact">
          <div class="bold-medium">Or email us at:</div>
          <div><a href="mailto:support@mychinaroots.com" class="link">support@mychinaroots.com</a></div>
        </div>
      </stand-out-block>
      <stand-out-block v-else>
        <div class="text-sm subtitle">What type of help or feedback do you need?</div>
        <div class="pre-buttons">
          <mcr-button class="white medium" @click="setHelpType('custom_research')">Custom Research</mcr-button>
          <mcr-button class="white medium" @click="setHelpType('default')">Database/Records/Tree</mcr-button>
        </div>
        <div class="alternative-contact">
          <div class="bold-medium">Or email us at:</div>
          <div><a href="mailto:support@mychinaroots.com" class="link">support@mychinaroots.com</a></div>
        </div>
      </stand-out-block>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';

import SupportContactForm from './SupportContactForm';

export default {
  metaInfo: {
    title: 'Contact Support',
  },
  data() {
    return {
      helpType: '',
    };
  },
  computed: {
    title() {
      return 'Need help or have feedback?';
    },
  },
  methods: {
    onSubmitted() {
      this.$toasted.success('Message sent! We’ll get back to you soon.');
      this.$refs.form.cleanup();
    },
    setHelpType(helpType) {
      this.helpType = helpType;
    },
  },
  components: {StandOutBlock, SupportContactForm, McrButton},
  name: 'SupportPage',
};
</script>

<style lang="scss" scoped>
.support-page {
  .readable_content.form_content {
    padding: 0;
    margin-top: 80px;
  }

  .title {
    margin-bottom: 20px;
  }

  .alternative-contact {
    padding-top: 20px;
    margin-top: 24px;
    border-top: 1px solid $neutral-200;
  }

  .subtitle {
    font-weight: 500;
    color: $neutral-500;
  }
  .pre-buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    .readable_content.form_content {
      margin-top: 40px;
    }
    .title {
      margin: 0 16px 16px;
    }
  }
}
</style>
