<template>
  <div class="support-contact-form">
    <div class="input-label">Help/feedback category</div>
    <single-option-select
      :value="helpType"
      :options="helpTypeOptions"
      :allow-remove="false"
      @select="onHelpTypeSelect"
    ></single-option-select>
    <div class="field-container no-margin">
      <div class="input-label">Tell us about your experience</div>
      <textarea id="comments" v-model="comment" class="input" rows="8"></textarea>
    </div>

    <upload-files :loading="loading" ref="uploadfiles"></upload-files>

    <mcr-button class="submit-button" @click="submitClick" :disabled="loading">
      <span v-if="loading" class="upload-status">Submitting Request... {{ loadingProgress }}%</span>
      <span v-else>{{ buttonLabel }}</span>
    </mcr-button>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import SingleOptionSelect from '@common/elements/filters/SingleOptionSelect';

import UploadFiles from './UploadFiles';

export default {
  props: {
    initHelpType: String,
  },
  data() {
    return {
      email: '',
      comment: '',
      helpType: this.initHelpType,
      helpTypeOptions: [
        {value: 'custom_research', name: 'Custom Research'},
        {value: 'default', name: 'Database/Records/Tree'},
      ],
    };
  },
  computed: {
    emailErrorText() {
      return 'Please enter a valid email address';
    },
    buttonLabel() {
      return 'Send Message';
    },
    loading() {
      return Boolean(this.$store.getters.supportFormLoadingState);
    },
    loadingProgress() {
      return this.$store.getters.supportFormLoadingProgressState;
    },
  },
  methods: {
    submitClick() {
      if (this.loading) {
        return;
      }
      const formData = this.getFormData();
      this.$store
        .dispatch('sendSupportFormAction', formData)
        .then(response => {
          this.$emit('submitted', formData);
        })
        .catch(() => {
          this.$toasted.error('Error. Please try again later.');
        });
    },
    getFormData() {
      return {
        help_type: this.helpType,
        comment: this.comment,
        attachments: this.$refs.uploadfiles.attachments,
      };
    },
    cleanup() {
      this.comment = '';
      this.$refs.uploadfiles.attachments = [];
    },
    onHelpTypeSelect(value) {
      this.helpType = value;
    },
  },
  components: {SingleOptionSelect, McrButton, UploadFiles},
  name: 'SupportContactForm',
};
</script>

<style lang="scss" scoped>
.support-contact-form {
  .field-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    margin-top: 20px;

    &.no-margin {
      margin-bottom: 0;
    }
  }

  #comments {
    width: 100%;
    box-sizing: border-box;
  }
  .submit-button {
    margin-top: 24px;
  }
}
</style>
